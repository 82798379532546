<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
      <error-notification :show="!loading && noSum">
        <span>{{ $t('assignments.no_sum') }}</span>
      </error-notification>
    </div>

    <location-bar
      :with-filters="locationFilters"
      :all-selectors="locationAllSelectors"
      :initial-region="initialRegion"
      :initial-country="initialCountry"
      @selected-region="regionSelected"
      @selected-country="countrySelected"
    >
      <div class="flex xs12 md4">
        <team-bar
          :loading="loading"
          :disabled="loading || allRegionsSelected || allCountriesSelected"
          :with-filters="teamFilter"
          :all-selector="true"
          :initial-team="initialTeam"
          @selected-team="teamSelected"
        />
      </div>
    </location-bar>
    <div class="row">
      <div
        class="flex xs12"
        :class="{'md6': !selectedTeam && selectedCountry, 'md4': selectedTeam}"
      >
        <va-input
          color="info"
          type="number"
          v-model="form.region_assignment.value"
          :disabled="loading"
          :label="$t('assignments.inputs.regionAssignmentInput')"
          :error="!!form.region_assignment.errors.length"
          :error-messages="translatedErrors(form.region_assignment.errors)"
          @blur="validate('region_assignment')"
        />
      </div>
      <div
        class="flex xs12"
        :class="{'md6': !selectedTeam, 'md4': selectedTeam}"
        v-show="selectedCountry"
      >
        <va-input
          color="info"
          type="number"
          v-model="form.country_assignment.value"
          :disabled="loading"
          :label="$t('assignments.inputs.countryAssignmentInput')"
          :error="!!form.country_assignment.errors.length"
          :error-messages="translatedErrors(form.country_assignment.errors)"
          @blur="validate('country_assignment')"
        />
      </div>
      <div class="flex xs12 md4" v-show="selectedTeam">
        <va-input
          color="info"
          type="number"
          v-model="form.team_assignment.value"
          :disabled="loading"
          :label="$t('assignments.inputs.teamAssignmentInput')"
          :error="!!form.team_assignment.errors.length"
          :error-messages="translatedErrors(form.team_assignment.errors)"
          @blur="validate('team_assignment')"
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-input
          color="info"
          type="number"
          v-model="form.total_assignment.value"
          :disabled="loading"
          :label="$t('assignments.inputs.valueInput')"
          :error="!!form.total_assignment.errors.length"
          :error-messages="translatedErrors(form.total_assignment.errors)"
          @blur="validate('total_assignment')"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const LocationBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Location')
const TeamBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Team')

export default {
  name: 'assignment-form',
  components: {
    LocationBar,
    TeamBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    assignments: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    isNew () {
      return this.assignments.id === 0
    },
    noSum () {
      const region = this.getFormValue('region_assignment')
      const country = this.getFormValue('country_assignment')
      const team = this.getFormValue('team_assignment')
      const sum = Number(region) + Number(country) + Number(team)
      return sum !== 100
    },
    allRegionsSelected () {
      return this.selectedRegion && this.selectedRegion.id === 0
    },
    allCountriesSelected () {
      return this.selectedCountry && this.selectedCountry.id === 0
    },
  },
  data () {
    return {
      isLoading: {
        regions: false,
        countries: false,
      },
      isError: {
        regions: false,
        countries: false,
      },
      assignmentsDate: null,
      locationAllSelectors: ['regions', 'countries'],
      locationFilters: ['regions', 'countries'],
      selectedRegion: '',
      initialRegion: null,
      selectedCountry: '',
      initialCountry: null,
      teamFilter: '',
      initialTeam: null,
      selectedTeam: '',
      form: {
        region_assignment: {
          value: 0,
          validate: {
            required: true,
          },
          errors: [],
        },
        country_assignment: {
          value: 0,
          validate: {
            required: true,
          },
          errors: [],
        },
        team_assignment: {
          value: 0,
          validate: {
            required: true,
          },
          errors: [],
        },
        total_assignment: {
          value: 0,
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    assignments (val) {
      this.setAssignment(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
    'form.region_assignment.value' (val) {
      let current = Number(val)
      if (current > 100) current = 100
      if (!this.selectedCountry) {
        const left = Math.floor((100 - current) / 2)
        this.setFormValue('country_assignment', left)
      }
      this.setFormValue('region_assignment', current)
    },
    'form.country_assignment.value' (val) {
      let current = Number(val)
      if (current > 100) current = 100
      if (!this.selectedTeam) {
        const region = Number(this.getFormValue('region_assignment'))
        const left = 100 - (current + region)
        this.setFormValue('team_assignment', left)
      }
      this.setFormValue('country_assignment', current)
    },
    'form.team_assignment.value' (val) {
      let current = Number(val)
      if (current > 100) current = 100
      this.setFormValue('team_assignment', current)
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setAssignment (val) {
      let region = {
        id: 0,
        name: 'layout.selects.all_regions',
      }
      let country = {
        id: 0,
        name: 'layout.selects.all_countries',
      }
      let team = {
        id: 0,
        label: 'layout.selects.all_teams',
      }

      if (val.region) {
        region = val.region
      }

      if (val.country) {
        country = val.country
        if (country.region) {
          region = country.region
        }
      }

      if (val.team) {
        team = val.team
        if (val.team.country) {
          country = val.team.country
          if (val.team.region) {
            region = val.team.region
          }
        }
      }

      this.initialRegion = region
      this.initialCountry = country
      this.initialTeam = team

      const formVal = {
        total_assignment: val.value,
      }
      if (Number(val.region_assignment) === val.region_assignment) {
        formVal.region_assignment = val.region_assignment * 100
      }
      if (Number(val.country_assignment) === val.country_assignment) {
        formVal.country_assignment = val.country_assignment * 100
      }
      if (Number(val.team_assignment) === val.team_assignment) {
        formVal.team_assignment = val.team_assignment * 100
      }
      this.setFormData(formVal)

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.assignments) {
        this.setAssignment(this.assignments)
      }
    },
    teamSelected (team) {
      this.selectedTeam = team
    },
    regionSelected (region) {
      this.selectedRegion = region
    },
    countrySelected (country) {
      this.selectedCountry = country
      if (!this.selectedCountry) {
        this.teamSelected('')
      }
      if (this.selectedTeam && this.selectedTeam.district.country_id !== country.id) {
        this.initialTeam = null
      }

      if (this.currentUser.can('Teams', 'index') && this.selectedCountry) {
        this.teamFilter = `&country=${this.selectedCountry.id}`
      }
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const assignments = this.getFormData(this.assignments)
      assignments.value = Number(assignments.total_assignment)
      if (assignments.region_assignment) {
        assignments.region_assignment = parseFloat(assignments.region_assignment / 100).toFixed(2)
      }
      if (assignments.country_assignment) {
        assignments.country_assignment = parseFloat(assignments.country_assignment / 100).toFixed(2)
      }
      if (assignments.team_assignment) {
        assignments.team_assignment = parseFloat(assignments.team_assignment / 100).toFixed(2)
      }

      if (this.selectedRegion && this.selectedRegion.id !== 0) {
        assignments.region_id = this.selectedRegion.id
      } else {
        assignments.region_id = null
      }

      if (this.selectedCountry && this.selectedCountry.id !== 0) {
        assignments.country_id = this.selectedCountry.id
      } else {
        assignments.country_id = null
      }

      if (this.selectedTeam && this.selectedTeam.id !== 0) {
        assignments.team_id = this.selectedTeam.id
      } else {
        assignments.team_id = null
      }

      delete assignments.team
      delete assignments.region
      delete assignments.country

      this.assignedError = false
      this.$emit('submit', assignments)
    },
  },
}
</script>
